import React from "react"
import SEO from "../../components/seo"
import HeroLandingPage from "../../components/pages/shared/hero/landing"
import FeaturesComponent from "../../components/pages/main/landing-features"
import PricingComponent from "../../components/pages/main/detail-pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import EmailChecklist from "../../components/pages/main/email-checklist"
import Banner from "../../components/pages/main/banner"
import Cards from "../../components/pages/main/cards"
import MainPageInfo from "../../components/pages/main/info"
import Discount from "../../components/pages/main/discount"
import EducationAccordionComponent from "../../components/pages/education/accordion"
import Breadcrumb from '../../components/controls/breadcrumbs';

const KinderEductionPage = (props) => {
        const crumbs = [
                { name: 'Главная', url: '/'},
                { name: 'Обучение', url: ''}
        ];
        return (
          <Layout headerColor={"#6D20D2"} page={"landing_education"}>
                  <SEO title="CRM-система для детских и образовательных центров"
                       description="Доступная CRM для детских центров и клубов. Автоматизация учета клиентов и продаж, календарь занятий, расчет зарплаты, интеграция с сайтом и многое другое. Демо-доступ 10 дней бесплатно."
                       url={"https://paraplancrm.ru" + props.location.pathname}
                       ogImage={"https://paraplancrm.ru/images/seo/education.png"}/>
                  <HeroLandingPage
                    title='CRM-система учета'
                    subtitle="для детских и образовательных центров"
                    idUnderBlock="#features"
                    offsetHeader={80}
                    list={[
                            '<b>Прозрачные</b> бизнес-процессы онлайн.',
                            '<b>Нет доплат</b> за новых учеников и сотрудников.',                            
                            '<b>Встроенное</b> расписание и <b>интеграция</b> с мессенджерами.',
                    ]} />
                  <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
                  <FeaturesComponent title='В системе есть все, что вам нужно' subtitle="А не только инструменты для работы с заявками и контроля отдела продаж" />
                  <Discount />
                  <MainPageInfo
                    title='Работа с CRM - это переход на новый уровень'
                    subtitle='Параплан CRM создан специально для учета клиентов и продаж в детских и образовательных центрах, спортивных клубах, фитнес-центрах, танцевальных студиях и в творческих мастерских' />
                  <EmailChecklist />
                  <Cards />
                  <Banner />
                  <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
                  <ContactFormComponent />
                  <EducationAccordionComponent />
          </Layout>
        )
}

export default KinderEductionPage
